import React from "react"
import SEO from "../components/seo"

import { CenteredTextBlock } from "../components/blocks/CenteredTextBlock"
import BasicTemplate from "../components/BasicTemplate"
import { Activity } from "../components/Activity"
import { config } from "../../config"
import { Typography } from "@material-ui/core"

const activities = [
  {
    name: "Queen of Hearts",
    subtitle: "Tuesdays",
    image: "https://i.imgur.com/OLJybrl.png",
    startTime: "7:00pm",
    description: "Queen of Hearts Drawing at 7:00 P.M",
  },
  {
    name: "Xtreme Bar Bingo",
    subtitle: "Tuesdays",
    image: "https://i.imgur.com/2GCVwEc.png",
    startTime: "7:30pm",
    description: "Follows the Queen of Hearts Drawing at 7:30 P.M! ",
  },
  {
    name: "KC Raffle",
    subtitle: "Wednesdays",
    image: "https://i.imgur.com/dtHHKsG.png",
    startTime: "6:30pm",
    description:
      "KC raffle held in the second and fourth Wednesday of the month.",
  },
  {
    name: "Fish Fry",
    subtitle: "Fridays",
    image: "https://i.imgur.com/JVwciMC.jpg",
    startTime: "4:00pm",
    endTime: "7:00pm",
    description:
      "Always a great night for our Fish Fry – unprecedented in the area, from 4:00 to 8:00 P.M.",
  },
  // {
  //   name: "Bingo",
  //   subtitle: "Sundays",
  //   image: "https://i.imgur.com/GnDHqMO.jpg",
  //   startTime: "6:15pm",
  //   description: "BINGO starts with an early bird game at 6:15 P.M. Regular games begin at 6:30 P.M.",
  // },
]

const ActivitiesPage = () => {
  return (
    <BasicTemplate title="Weekly Activities">
      <span>
        <SEO
          title="Weekly Events / Activities"
          description="Bingo Every Sunday! Come here for the Queen of Hearts drawing on Tuesday nights! We hold a raffle on the second and fourth Wednesday of the Month! Fish Fry every Friday!"
        />
        <CenteredTextBlock
          title="Join Our Weekly Events"
          style={{ paddingBottom: 20 }}
        >
          The KC Hall of O'Fallon has great weekly activities like Bingo,
          Raffles, Fish Fries, and more! Come on down and check us out!
        </CenteredTextBlock>

        {activities.map((activity, i) => (
          <Activity {...activity} key={i} />
        ))}

        <CenteredTextBlock title="Concert Series">
          Always look for the next concert in the January time frame. In the
          past we have had tribute artists for Elvis, Buddy - Holly, Patsy
          Cline, the Rat Pack, Elton John, The Eagles, Bob Seeger, Garth Brooks,
          and many more! This January will be a Journey tribute band to rock the
          HALL <br/><br/><br/>

          <a className="body-link" href={`${config.facebookLink}`}>
            Check out our facebook page for upcoming concert series!
          </a>
        </CenteredTextBlock>
      </span>
    </BasicTemplate>
  )
}

export default ActivitiesPage
